import { analytics } from 'src/analytics'
import { classTracker } from 'src/classTracker'
import { WEBPACK_ENV_CONFIG } from '../webpackEnvConfig'

const obe = {
  ...WEBPACK_ENV_CONFIG,
  analytics,
  classTracker,
  storage_keys: {
    promotion_code: 'Z2ltbWVnaW1tZQ==',
    coupon_allows_giftcards: 'true',
    split_default_key: 'anonymous',
    swr_me_key: 0,
  },
  events: {
    general: {
      module_interacted: 'Module Interacted',
    },
    class_stack: {
      class_stacked: 'classStacked',
      stack_started: 'Stack Started',
      stack_edited: 'Stack Edited',
    },
    bookmarks: {
      bookmark_vod: 'classBookmarked',
      created_new_collection: 'Created New Collection',
      deleted_collection: 'Deleted Collection',
      followed_collection: 'Following Friends Collection',
      renamed_collection: 'Renamed Collection',
      removed_from_all_collections: 'Removed From All Collections',
      removed_from_collection: 'Removed from Collection',
      saved_to_collection: 'Saved to Collection',
    },
    cancellation_aborted: 'Cancellation Aborted',
    cancellation_started: 'Cancellation Started',
    carousel: {
      billboard: {
        viewed_carousel: 'Viewed Billboard Carousel',
        viewed_tile: 'Viewed Billboard Tile',
      },
      classes: {
        viewed_carousel: 'classCarouselViewed',
        viewed_tile: 'classCardViewed',
      },
      instructors: {
        viewed_carousel: 'Viewed Instructors Carousel',
        viewed_tile: 'Viewed Instructor Tile',
      },
      programs: {
        viewed_carousel: 'Viewed Programs Carousel',
        locked_program_card_pressed: 'Locked Program Card Pressed',
      },
    },
    clicked_browse_more_classes_cta: 'browseMoreClassesCTATapped',
    clicked_add_notes_button: 'Clicked Add Notes Button',
    clicked_cancel_membership_button: 'Clicked Cancel Membership Button',
    clicked_edit_notes_button: 'Clicked Edit Notes Button',
    clicked_feature_announcement_cta: 'Clicked Feature Announcement CTA',
    clicked_guest_pass_email_invite: 'Clicked Guest Pass Email Invite',
    clicked_obe_collection: 'Clicked Obe Collection',
    clicked_obe_collection_video: 'Clicked Obe Collection Video',
    clicked_sft_mp_aggregate: 'Clicked SFT-MP-Aggregate',
    clicked_sft_button: 'SFT Button Pressed',
    closed_cancellation_modal: 'Closed Cancellation Modal',
    closed_notes_modal: 'Closed Notes Modal',
    closed_ratings_modal: 'Closed Ratings Modal',
    copied_guest_pass_link: 'Copied Guest Pass Link',
    homepage_loaded: 'Homepage Loaded',
    experiment_launched: 'Experiment Launched',
    impressions: {
      notes: 'Viewed Notes',
      notes_modal: 'Viewed Notes Modal',
      ratings_modal: 'Viewed Ratings and Notes Modal',
      class_count_modal: 'Viewed Class Count Modal',
      landing_page_modal: 'Viewed Landing Page Modal',
    },
    joined_recommended_class: 'Joined Recommended Class',
    landing_page_cta_pressed: 'Landing Page CTA Pressed',
    landing_page_block_item_pressed: 'Landing Page Block Item Pressed',
    launched_feature_announcement_modal: 'Launched Feature Announcement Modal',
    navigation: {
      navigated_to_collection: 'Navigated to Collection',
      navigated_to_guest_pass: 'Navigated to Guest Pass',
      navigated_to_profile: 'Navigated to Profile',
    },
    program: {
      viewed: 'Viewed Program',
    },
    progress_tracker: {
      selected_tab: 'User Selected Tab on Progress Tracker',
      navigated_to_class_history: 'User Navigated to Class History',
    },
    pt_landing_page: {
      clicked_cta: 'Landing Page CTA pressed',
    },
    quiz: {
      launched: 'User Launched Quiz',
      skipped_phone_question: 'Phone Collection Skipped',
      answered_question: 'Quiz Answer Submitted',
      completed: 'Quiz Completed',
    },
    guided_experience: {
      pressed_onboarding_module: 'Gex Onboarding Module Pressed',
      onboarding_launched: 'Gex Onboarding Launched',
    },
    ratings: {
      ratings_or_notes_submitted: 'ratingsOrNotesSubmitted',
    },
    filters: {
      popular_music: {
        toggled_filter: 'popularMusicFilterToggled',
        untoggled_filter: 'popularMusicFilterUntoggled',
      },
      hide_completed_classes: {
        toggled_filter: 'hideCompletedClassesFilterToggled',
        untoggled_filter: 'hideCompletedClassesFilterUntoggled',
      },
      quick_filter_selected: 'Quick Filter Selected',
      quick_filter_removed: 'Quick Filter Removed',
    },
    search_returned: 'Search Returned',
    selected_cancellation_reason_option: 'Selected Cancellation Reason Option',
    share_class_initiated: 'Share Class Initiated',
    shared_class: 'Shared Class',
    social: {
      clicked_add_friend_button: 'Clicked Add Friend Button',
      clicked_cancel_invitation_button: 'Clicked Cancel Invitation Button',
      clicked_ignore_invitation_button: 'Clicked Ignore Invitation Button',
      clicked_invite_contacts_button: 'Clicked Invite Contacts Button',
      unfriended_user: 'Unfriended a User',
      user_deselected_searchable: 'User Deselected Make Profile Searchable',
      user_selected_searchable: 'User Selected Make Profile Searchable',
    },
    sort_classes_resulted: 'Sort Classes Returned',
    submit_class_notes: 'Submitted Class Notes',
    submit_class_rating: 'Submitted Class Rating',
    timeline_scrolled: 'Timeline Scrolled',
    tour: {
      launched_tour: 'Product Tour Launched',
      started_tour: 'User Started Product Tour',
      next_slide: 'Product Tour Next Button Clicked',
      completed_tour: 'User Completed Product Tour',
      close_tour: 'User Closed Product Tour',
    },
    user_action: 'User Performed an Action',
    user_cleared_filters: 'User Cleared Filters',
    user_deselected_filter: 'User Deselected Filter',
    user_link: 'User Clicked Link',
    user_selected_billboard: 'User Selected Billboard',
    user_selected_filter: 'User Selected Filter',
    user_viewed_filter_results: 'filterResultsViewed',
    user_viewed_streak: 'User Viewed Streak',
    viewed_manage_subscription_page: 'Viewed Manage Subscription Page',
    viewed_download_app_modal: 'Viewed Modal',
    vod: {
      added_to_cal: 'Added to Calendar',
      ended: 'Video Completed',
      paused: 'Video Paused by User',
      played: 'Video Played by User',
      progress: 'Video in Progress',
      removed_from_cal: 'VOD Removed from Calendar',
      seeked: 'Video Seek by User',
      started: 'Video Started',
      viewed: 'Viewed VOD',
    },
    volume_status_set: 'Volume Status Set',
    workoutParty: {
      chatMsgSent: 'User Sent Chat',
      joined: 'User Joined Workout Party',
    },
    opened_calendar_modal: 'Add To Calendar Icon Pressed',
    my_schedule_item_pressed: 'My Schedule Item Pressed',
    recommendation_banner_pressed: 'Recommendation Module Pressed',
  },
  fitness_level_options: [
    {
      value: 'beginner',
      label: 'Baby steps please!',
    },
    {
      value: 'intermediate',
      label: 'I move 2-3x per week',
    },
    {
      value: 'advanced',
      label: 'I move 4+ times per week',
    },
    {
      value: 'prenatal',
      label: 'Keep me and baby active and safe',
    },
    {
      value: 'postnatal',
      label: 'Coming back after baby',
    },
  ],
  freemium: {
    max_freemium_classes_count: 3,
  },
  referral_type_options: [
    {
      id: 'appStore',
      label: 'App Store',
    },
    {
      id: 'digitalAd',
      label: 'Digital ad',
    },
    {
      id: 'friendOrFamily',
      label: 'Friend or family member',
    },
    {
      id: 'influencerOrCelebrity',
      label: 'Influencer or celebrity',
    },
    {
      id: 'instructor',
      label: 'Instructor',
    },
    {
      id: 'press',
      label: 'Press',
    },
    {
      id: 'tvAd',
      label: 'TV ad',
    },
    {
      id: 'other',
      label: 'Other',
    },
  ],
  class_sort_options: [
    {
      value: 'newest',
      label: 'Newest',
    },
    {
      value: 'default',
      label: 'Most relevant',
    },
    {
      value: 'popularity',
      label: 'Popular',
    },
  ],
  ui_component_type: {
    add_friend_modal: 'add friend modal',
    community: 'community',
    contacts_modal: 'contacts modal',
    friend: 'friend',
    friend_search_modal: 'friend search modal',
    self: 'self',
    vod_attendee_summary_modal: 'vod attendee summary modal',
  },
  level_up_club: {
    aborted: 'Level Up Aborted',
    clicked_upgrade_now: 'Clicked Upgrade Now Button',
    clicked_upgrade_started: 'Clicked Upgrade Subscription - Started',
    clicked_upgrade_next: 'Clicked Upgrade Subscription - Next',
    clicked_upgrade_final: 'Clicked Upgrade Subscription - Final',
    banner_dismissed: 'Level Up Banner Dismissed',
    started: 'Level Up Started',
    video_clicked: 'Level Up Club Video Clicked',
    video_played: 'Level Up Club Video Played',
    video_play_attempted: 'Level Up Club Video Play Attempted',
  },
  promo_codes: {
    guest_pass: 'GUESTPASSV2',
  },
  rejoin_now: 'Rejoin Now Clicked',
  quizzes: {
    onboarding: {
      slug: 'guided-experience-onboarding',
      resultsRoute: '/home',
    },
    pt_onboarding: {
      slug: 'personal-training-onboarding',
      resultsRoute: '/home',
    },
    gex_legacy_users: {
      slug: 'gex-legacy-users-quiz',
      resultsRoute: '/onboarding/guided-experience-results',
    },
    quiz_before_paywall: {
      slug: 'quiz-before-paywall',
      resultsRoute: '/obe-preview',
    },
    quiz_checkin: {
      slug: 'quiz-checkin',
      resultsRoute: '/home',
    },
  },
  videos: {
    max_class_count_shown: 10000,
    max_class_count_copy: 'More than 10k Classes',
  },
  signupRoute: '/obe-quiz',
}

export default obe
